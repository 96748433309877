import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useQuery } from '../hooks/useQuery';

import Layout from '../components/layout';

function Page({ pageContext }) {
  const data = useQuery();
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title || '',
    description: page.frontmatter.description || '',
    imgLocation: `${data.site.siteMetadata.siteUrl}` || '',
    socialImg: page.frontmatter.featuredSocial,
    socialTitle: page.frontmatter.socialTitle
      ? page.frontmatter.socialTitle
      : page.frontmatter.title,
    socialImgWidth: page.frontmatter.featuredSocial
      ? page.frontmatter.featuredSocial?.childImageSharp.original.width
      : page.frontmatter.featuredMobile?.childImageSharp.original.width,
    socialImgHeight: page.frontmatter.featuredSocial
      ? page.frontmatter.featuredSocial?.childImageSharp.original.height
      : page.frontmatter.featuredMobile?.childImageSharp.original.height,
    socialImgType: page.frontmatter.featuredSocial
      ? page.frontmatter.featuredSocial.internal.mediaType
      : page?.frontmatter?.featuredMobile?.internal?.mediaType,
  };
  const styles = {
    bannerColor: page.frontmatter.bannerColor,
    headingsColor: page.frontmatter.headingsColor,
    headingsFont: page.frontmatter.headingsFont,
    h2FontSize: page.frontmatter.h2FontSize,
    h3FontSize: page.frontmatter.h3FontSize,
    h4FontSize: page.frontmatter.h4FontSize,
    imgBorderColor: page.frontmatter.imgBorderColor,
    imgBorderWidth: page.frontmatter.imgBorderWidth,
  };

  const image = getImage(page.frontmatter.featuredImage);
  const mobile = getImage(page.frontmatter.featuredMobile);

  return (
    <Layout meta={meta} styles={styles}>
      <div>
        <header
          className={`has-text-centered prayer-header ${page.frontmatter.size} ${page.frontmatter.slug}`}
        >
          <h1 className="screen-reader-text">{page.frontmatter.title}</h1>
          {page.frontmatter.featuredImage ? (
            <GatsbyImage
              image={image}
              alt={page.frontmatter.featuredAlt}
              className={`headerImage ${
                page.frontmatter.featuredMobile ? 'desktopImage' : ''
              }`}
            />
          ) : (
            ''
          )}
          {page.frontmatter.featuredMobile ? (
            <GatsbyImage
              image={mobile}
              alt={page.frontmatter.featuredAlt}
              className="headerImage mobileImage"
            />
          ) : (
            ''
          )}
          {page.frontmatter.logoBlock !== false ? (
            <div className="logo-block">
              <a
                href="https://billygraham.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2022/01/bgea-logo-white.svg"
                  alt="Billy Graham Evangelistic Association Always Good News"
                />
              </a>
            </div>
          ) : (
            ''
          )}
          {page.frontmatter.featuredCaption && (
            <div>
              <figcaption id="header-caption" className="has-text-centered">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.frontmatter.featuredCaption,
                  }}
                />
              </figcaption>
            </div>
          )}
        </header>
        <main className="content container py-5">
          <div className="align_center">
            <MDXRenderer>{page.body}</MDXRenderer>
          </div>
        </main>
      </div>
    </Layout>
  );
}

export default Page;
