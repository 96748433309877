import { useStaticQuery, graphql } from 'gatsby';

export const useQuery = () => {
  const props = useStaticQuery(graphql`
    query MyQuery {
      site: site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  return props;
};
