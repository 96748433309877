import React, { useState, useEffect } from 'react';
import './AugustDetails.scss';
import { getQueryStringDict } from '@bgea-js/react-utilities';

function AugustDetails() {
  const [sourceCodes, setSourceCodes] = useState('BL248L000');
  const handleUrlChange = () => {
    const queryStringDict = getQueryStringDict();

    Object.entries(queryStringDict).forEach(([key, value]) => {
      if (key?.toLocaleLowerCase() === 'source') {
        setSourceCodes(value);
      }
    });
  };

  useEffect(() => {
    handleUrlChange();
  }, []);
  const franklinChangedLifeDomain =
    'https://billygraham.org/video/franklin-graham-a-walk-that-changed-his-life/';
  const franklinChangedLifeLink = `${franklinChangedLifeDomain}?source=${sourceCodes}`;

  return (
    <div>
      <div>
        <div>
          <img src="/resources/gold-coast-crowd_240603.jpg" alt="" />
          <figcaption>
            MORE THAN 270 PEOPLE WALKED FORWARD at the invitation to make
            life-changing decisions for Jesus Christ during the Gold Coast Look
            Up Celebration with Will Graham in Australia.
          </figcaption>
        </div>
        <p>
          &emsp;Carlos* had overcome drug addiction but he was still struggling
          with alcohol and gambling. He was desperate for the freedom found in
          Christ alone and was ready to surrender his eternity to Him. This is
          not a normal occurrence, but Carlos yelled out to me during the
          invitation, wanting to talk to me and pray with me.
          <span id="smaller-image" className="image-float-left">
            <img src="/resources/counsel_gold_coast.jpg" alt="" />
          </span>
          One of my colleagues conversed with him and then brought him to the
          side of the stage, where{' '}
          <strong>
            {' '}
            I had the blessing of personally praying with this new brother in
            Christ, giving thanks for his decision and lifting up his struggles
            to the Lord.
          </strong>
        </p>
        <p>
          &emsp;Will you join me in praying for Carlos and each of the more than
          275 people who responded to the Gospel? Pray also for the faithful,
          Biblical churches in the region as they begin the follow-up process
          and help these new believers grow in their faith.
        </p>
        <h3
          className="specialstyling mt-6"
          id="a-legacy-of-gospel-proclamation"
        >
          <span className="category">NATIONAL LAW ENFORCEMENT MINISTRY</span>
        </h3>
        <h4>Healing for Law Enforcement Couples</h4>
        <p>
          &emsp;A few years ago, we began holding special Marriage Resiliency
          Week events in Alaska for law enforcement couples.{' '}
          <strong>
            It’s our way of supporting those who sacrifice so much to protect
            and serve their communities.
          </strong>{' '}
          Attendees have experienced trauma in their roles and are struggling to
          cope with what they have endured in the line of duty.
        </p>
        <p>
          &emsp;Steve and Carol* were very reserved when they arrived recently
          for one of these weeks. Their daughter died in a car accident in 2021,
          and Steve had been involved in a traumatic shooting during a domestic
          violence call. The couple was at the breaking point in their marriage.
        </p>
        <div className="image-float-right">
          <img src="/resources/alaska-rrt_240706.jpg" alt="" />
          <figcaption>
            PARTICIPANTS PRAY FOR ONE ANOTHER during a Marriage Resiliency Week
            in Alaska. These weeklong events are designed to help law
            enforcement couples find strength in Jesus Christ.
          </figcaption>
        </div>
        <p>
          &emsp;Over the course of the week, they learned how to rebuild their
          marriage, where their true identity is found, and what it means to
          have the everlasting hope of the living God. By the end of their time
          in Alaska, Carol—a committed follower of Christ—had the joy of
          watching her hardened husband surrender his life to the Lord and be
          baptized in the frigid lake beside the lodge.
        </p>
        <p>
          &emsp;Before they left, Steve and Carol wrote, “Thank you so much for
          loving on us all week. You all were my favorites and will hold a
          special place in my heart. This is not goodbye! It’s see you later.”
        </p>
        <p>
          &emsp;Please join me in praying for them and all of the couples who
          will be with us this summer in Alaska. Law enforcement officers don’t
          always get the support they need and deserve, but we’re committed to
          standing alongside them in the Name of Jesus.
        </p>
        <h4>A GOLDEN GOSPEL ANNIVERSARY</h4>
        <p>
          &emsp;July 1974 is a special date for my family. It was then that my
          father, Franklin Graham—who had spent his youth running from
          God—repented and surrendered to Christ as Savior. This month, he marks
          50 years since making that eternally important decision!&nbsp;{' '}
          <strong>
            <a
              href={franklinChangedLifeLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              You can watch my father tell this story
            </a>
            ,&nbsp;&nbsp;and I hope you’ll share it with someone who needs to
            hear the Good News.
          </strong>
        </p>
        <p>
          &emsp;He recently recorded a special video which we aired on
          TV—including on the national ABC network during{' '}
          <em>World News Tonight</em> and <em>Good Morning America</em>. Through
          this Gospel spot, millions of people heard about how Jesus saved my
          father—and how He can do the same for them. Thousands called to talk
          and pray with trained ministry representatives, who helped answer
          their questions and point them to Jesus Christ. Thousands more made
          decisions for the Lord on their own at home.
        </p>
        <p>
          &emsp;Lola was one of those who called.{' '}
          <strong>
            She said that she had seen the spot numerous times that day; by the
            fourth time, she figured God was speaking directly to her.
          </strong>{' '}
          Alone in her home, she stopped and prayed to surrender her life to
          Jesus Christ. As she talked with our representative, Lola shared that
          she is battling cancer. She had wanted to begin a relationship with
          the Lord, but something had always stopped her—until now. Our team
          member helped Lola find a Bible-teaching church near her, prayed for
          her, and shared how she can grow closer to Jesus. Before their
          conversation ended, Lola said she planned to attend the church on
          Sunday!
        </p>
        <p>
          &emsp;Please pray for her and all of those who surrendered their lives
          to the Lord after watching this Gospel spot.
        </p>
        <h3
          className="specialstyling mt-6"
          id="a-legacy-of-gospel-proclamation"
        >
          <span className="category">BILLY GRAHAM RAPID RESPONSE TEAM</span>
        </h3>
        <h4>‘You were on Jesus’ mind …’</h4>
        <p>
          &emsp;Because of wildfires, tornadoes, and flooding, many people
          across the U.S. are currently walking through their darkest days. The
          Billy Graham Rapid Response Team of crisis-trained chaplains has had
          an unusually busy summer. As I write this, they’re ministering in
          seven different states, offering the hope of Jesus Christ to grieving
          individuals and families.
        </p>
        <p>
          &emsp;One man in Bell County, Texas, was struggling in the aftermath
          of a tornado that devastated his community in May.
        </p>
        <p>
          &emsp;The homeowner began sharing with our team that he knew of Jesus
          but never truly understood what it meant to be in a relationship with
          Him. This prompted one of our volunteers to begin sharing her story.{' '}
          <strong>
            As she spoke, she looked at the man and said, “As Jesus hung on the
            cross, you were on Jesus’ mind.”
          </strong>{' '}
          This resonated with the homeowner. The wall he had built around his
          heart came crashing down as he understood Christ’s love for him. He
          joyfully prayed to surrender his life into the hands of the Savior.
        </p>
        <h4>GREAT IS HIS FAITHFULNESS</h4>
        <p>
          &emsp;My friends, God is indeed faithful. “
          <em>
            Through the Lord’s mercies we are not consumed, because His
            compassions fail not. They are new every morning; great is Your
            faithfulness
          </em>
          ” (Lamentations 3:22–23, NKJV). Every day we see His goodness—through
          the doors He opens to proclaim His hope, through the souls He calls to
          Himself, and through partners in ministry like you who make it all
          possible. May God bless and encourage you as His Word goes out, thanks
          to your support and generosity.
        </p>
      </div>
      <div>
        <p>
          <br />
          May God bless you,
        </p>
        <img
          className="signature"
          src="https://static.billygraham.org/sites/billygraham.org/uploads/prod/2022/02/Will-Graham-Signature.png"
          alt=""
        />
        <p>
          Will Graham
          <br />
          Executive Vice President
        </p>
      </div>
      <div className="give-background">
        <h4 className="cta projects mt-6">
          <span>YOU</span> CAN MAKE A DIFFERENCE
        </h4>
        <h4 className="mb-6 smaller">
          GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF
          THE GOSPEL.
        </h4>
        <p>
          <span className="options">WHERE MOST NEEDED</span> | The Gospel
          changes lives—now and for eternity. When you designate a gift for{' '}
          <strong>“Where Most Needed,”</strong> it is available for immediate
          ministry opportunities and needs, including the personnel, resources,
          materials, equipment, and support services vital to the work of
          evangelism around the world.
        </p>
        <p>
          <span className="options">EVANGELISTIC CRUSADES</span> | So many
          people are living without the hope that comes from Jesus Christ. When
          you send a gift for <strong>Evangelistic Crusades</strong>, you give
          people across the globe the chance to hear the Gospel at events like
          the upcoming Will Graham Celebration in Northern Colorado and Franklin
          Graham Festival in Naples, Italy.
        </p>
        <p>
          <span className="options">NATIONAL LAW ENFORCEMENT MINISTRY </span> |
          This year, hundreds of law enforcement officers and their spouses will
          attend appreciation retreats and weeklong Marriage Resiliency Week
          events in Alaska. Your support makes it possible to bless them with
          this time away and strengthen them in their calling as they
          sacrificially serve our nation.
        </p>
        <p>
          <span className="options">
            BILLY GRAHAM RAPID RESPONSE TEAM MINISTRY{' '}
          </span>{' '}
          | Your contribution in support of the{' '}
          <strong>Billy Graham Rapid Response Team</strong> helps make it
          possible for crisis-trained chaplains to deploy at a moment’s notice
          to communities devastated by natural or man-made disasters. Your gift
          enables these men and women to share God’s love with hurting people.
        </p>
      </div>
    </div>
  );
}

export default AugustDetails;
